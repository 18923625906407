a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: var(--main-color);
  color: #fff;
  transition: all 0.3s;
  height: 100vh;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #070565;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 15px 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: var(--main-color);
  background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #fff;
  background: var(--color2);
}

a[data-toggle="collapse"] {
  position: relative;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: var(--color2);
}

#content {
  width: 100%;
  transition: all 0.3s;
}

#content2 {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: -252px;
    z-index: 40;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #sidebarCollapse span {
    display: none;
  }
}