@import url(https://fonts.googleapis.com/css?family=Inter);
@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(https://fonts.googleapis.com/css2?family=Public+Sans);
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200italic,300,300italic,regular,italic,600,600italic,700,700italic,900,900italic");
@import url("https://fonts.googleapis.com/css?family=Lexend:100,200,300,regular,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic");
@import url("https://fonts.cdnfonts.com/css/rashkey");
@import url("https://fonts.googleapis.com/css?family=DM+Sans:regular,italic,500,500italic,700,700italic");
@import url("https://fonts.googleapis.com/css?family=Krub:200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic");
/* @import url('https://fonts.cdnfonts.com/css/sansation'); */

#root {
	--main-color: #070567;
	--color2: #1a10c5;
	--color3: #ebf1ff;
	--color4: #10f2d7;
	--color5: #fbb100;
	--color6: #fdcb53;
	--color7: #ff9966;
	--color8: #929294;
	--color9: #6fcf97;
	--color10: #eb5757;
	--main-text: "Sansation";
	--main-gradient: linear-gradient(175.59deg, #0b61c7 -1.89%, #2f35cd 121.97%);
	--gradient-1: linear-gradient(106.75deg, #918fdb 0%, #0d03c3 100%);
	--gradient-2: linear-gradient(90.18deg, #2a5aff -52.19%, #2e49a7 81.92%);
}
body {
	scroll-behavior: smooth;
	background: #edebeb;
	overflow-x: hidden;
}
.section {
	background: #edebeb;
}
.mont {
	font-family: Montserrat;
}
.krub {
	font-family: Krub;
}
@font-face {
	font-family: "Sansation";
	src: url(./assets/Sansation_Regular.ttf);
}
@font-face {
	font-family: "FontAwesome";
	src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
}
@font-face {
	font-family: "Segoe UI";
	src: url("./assets/segoe.ttf");
}
.segoe {
	font-family: "Segoe UI";
}
.Dmsans {
	font-family: DM Sans;
}
.Nunito {
	font-family: "Nunito";
}
.Inter {
	font-family: "Inter";
}
.Rashkey {
	font-family: Rashkey;
}
.Lato {
	font-family: Lato;
}
.Lexend {
	font-family: Lexend;
}
.Source {
	font-family: Source Sans Pro;
}
.myCursor {
	cursor: pointer;
}
.sansation {
	font-family: "Sansation";
}
.poppins {
	font-family: Poppins;
}
.btn:disabled {
	background-color: auto !important;
}
.borderNone {
	border: none !important;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Sansation";
	font-size: 14px;
}
button,
button:focus,
input,
input:focus,
*:focus {
	outline: none !important;
	box-shadow: none !important;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
	border: 0 !important;
	box-shadow: none !important;
}
::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}
.noScroll::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #ebf1ff;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: #10f2d7;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: #fbb100;
	/* background: var(--color5); */
}
.main-bg,
.btn-main {
	background: var(--main-color) !important;
}
.fullHeight {
	min-height: 100vh !important;
}
.heroBg {
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	min-height: 100vh !important;
	height: 100% !important;
}
.logo {
	max-width: 150px;
}
.text-main,
.textColor2,
.btn-outline-primary {
	color: var(--main-color) !important;
}
.btn-outline-primary:hover {
	color: white !important;
}
.btn-outline-primary {
	border-color: var(--main-color) !important;
}
.btn-white {
	background: white !important;
}
.btn-primary,
.btn-outline-primary:hover {
	background: var(--main-color) !important;
}
.btn-yellow {
	background: var(--color5) !important;
}
.text-yellow {
	color: var(--color5) !important;
}
/* .landingText { */
/* font-family: var(--main-text);
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 70px;
	letter-spacing: 0.05em; */
/* } */
.defaultHead {
	height: 4rem !important;
}

.border-width-4 {
	border-bottom-width: 2px !important;
	border-bottom-color: rgba(204, 204, 204, 0.8) !important;
}
.border-width-4-color {
	border-bottom-color: var(--color2) !important;
}
.border-width-4-2 {
	border-bottom-width: 4px !important;
}

.btnAbs {
	height: 101%;
	width: 101%;
	background: var(--color5);
	position: absolute;
	z-index: -10;
	top: 7px;
	left: 7px;
	content: "";
}

/* .progress-bar {
	background-color: #454657 !important;
} */
.collapsible-link {
	width: 100%;
	position: relative;
	text-align: left;
}

.collapsible-link::before {
	content: "\f107";
	position: absolute;
	top: 50%;
	right: 0.8rem;
	transform: translateY(-50%);
	display: block;
	font-family: "FontAwesome";
	font-size: 1.1rem;
}

.collapsible-link[aria-expanded="true"]::before {
	content: "\f106";
}

.text-left {
	text-align: left !important;
}

.textTrunc {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.textTrunc2 {
	-webkit-line-clamp: 2;
}
.textTrunc3 {
	-webkit-line-clamp: 3;
}
.textTrunc4 {
	-webkit-line-clamp: 4;
}
.textTrunc5 {
	-webkit-line-clamp: 5;
}
.mobileLogin {
	background: #070565b3, url("/src/assets/loginbg.jpg");
}
.submitText {
	background: linear-gradient(90.18deg, #2a5aff -52.19%, #2e49a7 81.92%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}
.style-module_simpleStarRating__nWUxf svg {
	display: inline !important;
}

.force-d-flex,
.modal-title {
	display: flex !important;
	align-items: center !important;
}

.file_upload {
	overflow: hidden;
	position: relative;
	margin: 0;
}
.file_upload #file {
	top: 0;
	left: 0;
	right: 0;
	position: absolute;
	opacity: 0;
	height: 100%;
	width: 100%;
}

.modal-header .btn-close {
	background: transparent
		url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
		center/1em auto no-repeat !important;
}

.otp-code__input {
	border-radius: 0.5rem;
	font-family: monospace;
	height: 50px;
	border: 1px solid #ccc;
	font-size: 2rem;
	text-align: center;
	transition: all 0.2s ease;
	color: #222;
	width: 3rem !important;
	margin: 0 0.5rem;
}
/* .btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
	color: #fff !important;
	background-color: var(--color2) !important;
} */

.border-rounded-1 {
	border-radius: 15px 15px 0 15px !important;
}
.w-65 {
	width: 65% !important;
}
.border-rounded-2 {
	border-radius: 0px 15px 15px 15px !important;
}
.chatScreen {
	height: 66vh;
	overflow-y: auto;
}
.aboutScreen {
	max-height: 85vh !important;
	height: 100%;
}
